var nav = {
    activateEvent: function() {
        // nav event handler
        var subNavActive = '', subNavFlag = true;

        $(document).on('click', '.toggler-men-categories', function(e) {
            e.stopPropagation();
            nav.toggleNavCategories(this, '.men-categories', '.women-categories', '.toggler-women-categories');
        });

        $(document).on('click', '.toggler-women-categories', function(e) {
            e.stopPropagation();
            nav.toggleNavCategories(this, '.women-categories', '.men-categories', '.toggler-men-categories');
        });

        // toggling men and women nav/menu

        // this is for desktop if sub menu on side nav is exceed the height of window or the element is out of the window height.
        // reposition the sub menu if the statement above is true else do nothing.
        $(document).on('mouseenter', '.prolook-side-menu .side-submenu', function() {
            var submenu = $(this).find('.side-sub-menu');
            var currentPosition = parseInt(submenu.offset().top + submenu.height());
            var newTopPosition = parseInt($(window).height() - 30) - currentPosition;  // 30 is just a magical number

            if(currentPosition >= $(window).height()) {
                submenu.css('top', newTopPosition);
            }
        });

        $(document).on('click', '.prolook-side-menu .caret-side-submenu', function(e) {
            e.stopPropagation();
            var ua = navigator.userAgent.toLowerCase();
            if((ua.indexOf("android") >= 0) || (ua.indexOf("iphone") >= 0) || (ua.indexOf("ipad") >= 0) || (ua.indexOf("ipod") >= 0) ) {
                var sideSubMenu = $(this).parents('ul').find('.side-submenu');
                
                sideSubMenu.find('.side-sub-menu').removeClass('active');
                sideSubMenu.find('.caret-side-submenu').removeClass('fa-caret-down').addClass('fa-caret-right');

                var text = $(this).parents('.side-submenu').find('.side-sub-menu-parent').text().toLowerCase().trim();

                if(subNavActive !== text) {
                    subNavFlag = true;
                    $(this).parents('.side-submenu').find('.side-sub-menu').addClass('active');
                    $(this).removeClass('fa-caret-right').addClass('fa-caret-down');
                } else {
                    if(subNavFlag) {
                        subNavFlag = false;
                        $(this).removeClass('fa-caret-down').addClass('fa-caret-right');
                        $(this).parents('.side-submenu').find('.side-sub-menu').removeClass('active');
                    } else {
                        subNavFlag = true;
                        $(this).removeClass('fa-caret-right').addClass('fa-caret-down');
                        $(this).parents('.side-submenu').find('.side-sub-menu').addClass('active');
                    }
                }

                subNavActive = text;

                // $(this).removeClass('fa-caret-right').addClass('fa-caret-down');
            } else {
                window.location.href = $(this).parent().find('a').attr('href');
            }
        });
    },

    // detect if mobile or no hover state
    // if yes remove hover on side nav/menu to fix the conflict between click event of javascript and hover state on css
    checkifMobile: function() {
        var ua = navigator.userAgent.toLowerCase();
        if((ua.indexOf("android") >= 0) || (ua.indexOf("iphone") >= 0) || (ua.indexOf("ipad") >= 0) || (ua.indexOf("ipod") >= 0) ) {
            $('.prolook-side-menu').find('.side-submenu').removeClass('has-hover');
        } else {
            $('.prolook-side-menu').find('.side-submenu').addClass('has-hover');
        }
    },

    toggleNavCategories: function(_this, currCategory, prevCategory, togglerCategory) {
        $(_this).toggleClass('fa-angle-down fa-angle-up');
        $(currCategory).slideToggle();

        // always hide men when women categories is active
        $(prevCategory).slideUp();
        if($(togglerCategory).hasClass('fa-angle-up')) {
            $(togglerCategory).removeClass('fa-angle-up').addClass('fa-angle-down');
        }

        // remove sub menu active state
        var sideSubMenu = $('.prolook-side-menu').find('.side-submenu');
            
        sideSubMenu.find('.side-sub-menu').removeClass('active');
        sideSubMenu.find('.caret-side-submenu').removeClass('fa-caret-down').addClass('fa-caret-right');
        subNavActive = '';
        subNavFlag = true;
    }
};